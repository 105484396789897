.persona-info {
    margin-top: -37px;
}

.persona-info-container {
    border-left: 1px solid rgb(0 0 0/10%);
    background: #f7f6f8;
    padding: 12px;
    width: 275px;
    float: right;
}

.persona-container-side-pop {
    width: 275px;
    display: inline-block;
    float: right;
    height: calc(100vh - 106px);
}

.persona-info-container > svg {
    float: right;
    cursor: pointer;
    top: 4px;
    position: relative;
    right: 4px;
}

.persona-info-container .persona-profile-pic {
    display: flex;
    margin-top: 33px;
}

.persona-info-container .persona-profile-pic > img, .persona-info-container .persona-profile-pic > svg {
    height: 175px;
    width: 175px;
    object-fit: cover;
    border-radius: 100%;
    margin: auto;
}

.persona-info-container .persona-profile-pic > svg {
    padding: 25px;
    color: var(--main-dark-gray);
}

.persona-info-container .persona-info-label {
    margin-left: 15px;
}

.persona-info-container .persona-info-value {
    line-height: 1;
    margin: 2px 0 10px 15px;
    padding-left: 6px;

}

.persona-info-container .persona-actions {
    display: flex;
    margin: 28px 0 40px 0;
}

.persona-info-container .persona-action {
    margin: auto 0;
    cursor: pointer;
    width: 112px;
}

.persona-info-container .persona-action-icon {
    color: #383f45;
    background-color: lightgray;
    border-radius: 100%;
    height: 49px;
    width: 49px;
    display: flex;
    margin: auto;
}

/* .persona-info-container .persona-action-icon > .fas, .persona-info-container .persona-action-icon > .far {
    margin: auto;
} */

.persona-info-container .persona-action-icon > svg {
    margin: auto;
}

.persona-info-container .persona-action-text > p {
    text-align: center;
    font-size: 13px;
}

/* .persona-info-container .persona-action-icon > .fa-handshake {
    font-size: 18px;
} */

.persona-info-container .placeholder {
    height: 200px;
}

.persona-info-container .review-stars {
    float: right;
    margin-right: 20px;
}