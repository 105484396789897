.send-button-container {
    background-color: var(--light-green);
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    margin-left: -10px;
    border-radius: 6px;
    box-shadow: 2px 2px 8px lightgrey;
}

.send-button-container svg {
    margin: auto;
    margin-right: 9px;
}

.add-button-container svg{
    box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
    border-radius: 100%;
}