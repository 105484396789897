:root {
    --main-dark-blue: #364453;
    --main-light-green: #9bab90;
    --main-green: #95de82;
    --main-dark-gray: #383f45;
    --main-light-gray: rgba(0, 0, 0, 0.05);
    --border-light-gray: rgb(0 0 0 / 10%);
    --main-red-button: #d22b2b;
    --button-med-gray: #919191;
    --main-cream: #F5F3E5;
    --main-yellow: #F5FB3D;
    --main-charcoal: #202020;
    --main-brown: #9bab90;

}

@font-face {
    font-family: 'GilmerHeavy';
    font-style: normal;
    font-weight: bold;
    src:
      local('GilmerHeavy'),
      url(../images/fonts/Gilmer_Heavy.otf) format('opentype');;
}

@font-face {
    font-family: 'GilmerRegular';
    font-style: normal;
    src:
      local('GilmerRegular'),
      url(../images/fonts/Gilmer_Regular.otf) format('opentype');;
}

@font-face {
    font-family: 'GilmerOutline';
    font-style: normal;
    src:
      local('GilmerOutline'),
      url(../images/fonts/Gilmer_Outline.otf) format('opentype');;
}

@font-face {
    font-family: 'GilmerMedium';
    font-style: normal;
    src:
      local('GilmerMedium'),
      url(../images/fonts/Gilmer_Medium.otf) format('opentype');;
}

@font-face {
    font-family: 'GilmerBold';
    font-style: normal;
    src:
      local('GilmerBold'),
      url(../images/fonts/Gilmer_Bold.otf) format('opentype');;
}

@font-face {
    font-family: 'GilmerLight';
    font-style: normal;
    src:
      local('GilmerLight'),
      url(../images/fonts/Gilmer_Light.otf) format('opentype');;
}

body,
input,
button,
textarea,
select {
    margin: 0;
    font-family: "Baloo 2", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

textarea {
    resize: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h4 {
    margin: 0;
    color: var(--main-dark-gray);
}

p {
    margin: 0;
    color: #464646;
}

a,
button {
    cursor: pointer;
}

a:visited,
a:-webkit-any-link {
    color: inherit;
    text-decoration: none;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

input::placeholder {
    font-style: italic;
    color: lightgray;
    font-size: 17px;
}

#register-email::placeholder {
    font-size: 16px !important;
}

input[type="password"] {
    /* font: small-caption; */
    font-size: 17px !important;
    letter-spacing: 0.2em;
}

input[type="password"]::placeholder {
    font-size: 16px !important;
    letter-spacing: 0 !important;
}

.buttonMain {
    background-color: var(--main-brown);
    border: none;
    color: var(--main-dark-blue);
    border-radius: 6px;
    font-size: 17px;
    padding: 12px 30px;
    box-shadow: 3px 9px 8px lightgray;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    width: 160px;
}

.small-in-app-button {
    box-shadow: 5px 4px 10px lightgrey;
    height: 33px;
    border-radius: 6px;
    border: none;
    color: white;
    background-color: var(--button-med-gray);
    padding: 0px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.success-checkmark {
    font-size: 26px;
    padding: 18px;
    background-color: lightgreen;
    border-radius: 100%;
    color: white;
}

.str-chat__message-team-meta .str-chat__avatar--circle {
    cursor: pointer;
}

.mobile-only {
    display: none;
}

/****** Mobile Optimization ******/

@media only screen and (max-width: 800px) {
    .mobile-only {
        display: inherit;
    }

    .desktop-only {
        display: none !important;
    }

    .mobile-close {
        display: none !important;
    }

    /****** Messages ********/

    /* .channel__container {
      height: calc(100% - 160px)!important
    } */

    .team-channel-header__name {
        max-width: calc(100vw - 270px) !important;
    }

    .channel-list__list__wrapper {
        width: 100vw !important;
    }

    .persona-info-container {
        width: 100vw !important;
        height: calc(100vh - 105px) !important;
        float: none !important;
    }

    .channel-name-input__wrapper input {
        width: calc(100% - 160px) !important;
    }

    .leave-channel-button {
        margin-right: 18px;
    }

    .user-item__wrapper .admin-badge {
        font-size: 10px !important;
        padding: 3px 3px !important;
    }

    .user-item__name-wrapper .user-item__name {
        width: calc(100% - 100px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
    }
    .user-item__name-wrapper .admin-badge {
        margin-left: 2px !important;
    }

    .user-list__container .list__header {
        margin-right: 5px;
    }

    .user-item__name-wrapper {
        width: 58%;
    }

    .edit-channel__container .popup-messenger-mask {
        width: 100%;
    }

    /***** Org Connect ******/

    #oc-search-results .result {
        padding: inherit !important;
    }

    #oc-search-results .profile-info p{
        max-width: 115px;
    }

    #oc-search-results .result button {
        margin-right: 3% !important;
    }

    .org-connect-spotlight-open {
        width: 100vw !important;
        display: none !important;
    }

    #org-connect-mobile-user-profile {
        width: calc(100vw - 25px) !important;
    }

    
}


