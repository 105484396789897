.onboarding-container {
    padding: 35px;
    padding-top: 15px;
}

.onboarding-container .text-input {
    width: 100%;
    height: 25px;
    font-size: 15px;
    padding: 4px 8px;
    border: none;
    border-radius: 7px;
}

.onboarding-container textarea {
    height: 55px !important;
}

.onboarding-container .text-input-label {
    text-align: left;
    font-weight: 600;
    padding-left: 5px;
    padding-bottom: 4px;
    margin-top: 15px;
    text-shadow: 1px 1px black;
}

.onboarding-container select {
    width: calc(100% + 18px);
    height: 38px;
    border-radius: 7px;
    border: none;
}

.onboarding-container .file-input {
    cursor: pointer;
}

.onboarding-container .image-uploader {
    border-color: var(--main-cream);
    width: calc(100% - 13px);
}

.onboarding-container .image-uploader > div > span {
    color: var(--main-cream);
}

.onboarding-container .image-uploader .custom-upload-text-container {
    border: dashed 2px var(--main-cream);
    padding: 8px 15px;
    height: 48px;
    margin: auto;
    font-size: 13px;
    border-radius: 5px;
    text-align: left;
    padding-left: 20px;
    width: calc(100% - 23px);
    cursor: pointer;
}

.onboarding-container .image-uploader .custom-upload-text-container:hover {
    border: dashed 2px var(--main-yellow);
}

.onboarding-container .image-uploader .custom-upload-text {
    margin-top: 14px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.onboarding-container .image-uploader .custom-upload-text-left {
    float: left;
    max-width: 300px;
}

.onboarding-container .image-uploader .custom-upload-text-right {
    float: right;
    max-width: 100px;

}

.onboarding-container .select-validation-field {
    float: right;
    position: relative;
    right: 100px;
    top: -33px;
    color: transparent;
    border: none;
    height: 0;
    width: 0;
}

.loading-container {
    display: flex;
    height: 150px;
}

.loading-container > div {
    margin: auto;
}

.onboarding-container .react-tel-input .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 37.5px;
    padding-top: 3px;
    padding-left: 8px !important;
    margin-left: 39px !important;
    width: calc(100% - 23px) !important;
    border: none;
}

.onboarding-container .react-tel-input .flag-dropdown {
    border-radius: 7px 0 0 7px;
    border: none;
    border-right: 1px solid #CACACA;
}

.onboarding-container > .code-of-conduct {
    background-color: white;
    text-align: left;
    padding: 11px;
    border-radius: 6px;
    margin-top: 5px;
    color: black;
}

.onboarding-container .code-of-conduct h5 {
    font-size: 16px;
}
