
header {
    height: 80px;
    background-image: linear-gradient(to right, #9bab90, var(--main-brown));
}

header a {
    color: white;
    text-decoration: none;
}


header .menu-container {
    display: flex;
    height: 80px;
    float: left;
}

header .profile-container {
    display: flex;
    float: right;
    height: 80px;
}

header .profile-container img {
    height: 55px;
    border-radius: 100%;
    width: 55px;
    object-fit: cover;
}

header .profile-image {
    margin: auto 15px;
    height: 55px;
    margin-right: 25px;
    cursor: pointer;
}

header .logo {
    height: 60px;
    margin: auto;
    margin-top: 11px;
    margin-left: 23px;
    cursor: pointer;
}

header .navbar {
    display: flex;
    margin-left: 24px;
}

header .navbar-item {
    color: white;
    margin: auto 12px;
    cursor: pointer;
    padding-top: 6px;
    font-family: 'GilmerBold', 'Baloo2', serif;
}

header .fa-search {
    margin-left: -23px;
    color: darkgray;
    position: relative;
    top: 1px;
}

header .searchbar-container {
    margin: auto 20px;
    height: 28px;
}

header .searchbar-container a {
    display: inline-block;
    cursor: pointer;
}

#searchbar {
    height: 28px;
    width: 260px;
    border-radius: 3px;
    border: none;
}

header .drop-down-container {
    background-color: white;
    position: absolute;
    top: 69px;
    border: 1px solid lightgray;
    padding: 0px;
    width: 135px;
    right: 47px;
    border-radius: 8px;
    border-top-right-radius: 0;
    font-size: 14px;
    box-shadow: 2px 3px 9px rgb(0 0 0 / 10%);
    z-index: 99;
    min-width: fit-content;
}

header .drop-down-container p {
    padding: 10px 10px;
    border-top: 1px solid lightgray;
    cursor: pointer;
    display: block;
}

header .drop-down-container .profile-name {
    border-top: none;
    cursor: auto;
    font-size: 17px;
}

header .nav-dropdown-mask {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 90;
    left: 0;
    background: rgba(0,0,0,0);
}



