.profile-pic-placeholder {
    font-size: 50px;
    background-color: var(--main-dark-blue);
    margin: auto;
    border-radius: 100%;
    height: 165px;
    width: 165px;
    text-align: center;
    color: white;
    line-height: 3.3;
}

.profile-pic-placeholder-standard-small {
    font-size: 29px;
    background-color: var(--main-dark-blue);
    margin: auto 0;
    border-radius: 100%;
    height: 55px;
    width: 55px;
    text-align: center;
    color: white;
    line-height: 2;
}

.header-pic {
    height: 44px !important;
    width: 44px !important;
    padding-top: 0 !important;
    font-size: 23px !important;
    margin-top: 5px !important;
    line-height: 2 !important;
    background-color: var(--main-light-green);
}
