.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
.stepper-item {
position: relative;
display: flex;
flex-direction: column;
align-items: center;
flex: 1;

@media (max-width: 768px) {
    font-size: 12px;
}
}

.stepper-item::before {
position: absolute;
content: "";
border-bottom: 2px solid #e7e7e7;
width: 100%;
top: 20px;
left: -50%;
z-index: 2;
}

.stepper-item::after {
position: absolute;
content: "";
border-bottom: 2px solid #e7e7e7;
width: 100%;
top: 20px;
left: 50%;
z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e7e7e7;
    margin-bottom: 6px;
    margin-top: -1px;
}

.stepper-item .step-counter:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px 2px var(--main-yellow)
}

.stepper-item.active {
    font-weight: bold;
}

.stepper-item.active .step-counter {
    border: 3px solid var(--main-yellow);
}

.stepper-item.completed .step-counter {
background-color: var(--main-yellow);
}

.stepper-item.completed::after {
position: absolute;
content: "";
border-bottom: 2px solid var(--main-yellow);
width: 100%;
top: 20px;
left: 50%;
z-index: 3;
}

.stepper-item:first-child::before {
content: none;
}
.stepper-item:last-child::after {
content: none;
}