.channel__container {
    height: calc(100vh - 80px);
    width: 100%;
}

.str-chat__thread {
    z-index: 1;
}

.str-chat__thread-list .str-chat__message-team {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.str-chat__thread-list .str-chat__message-actions-list button:first-child {
    display: none;
}

.str-chat__list .str-chat__reverse-infinite-scroll {
    padding-top: 0px;
}

.str-chat__thread-list .channel-empty__container {
    display: none;
}

.str-chat__date-separator {
    padding: 20px 40px;
}

.custom-thread-header {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.custom-thread-header__left {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.custom-thread-header__left-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-right: 10px;
}

.custom-thread-header__left-count {
    font-size: 14px;
    color: #858688;
}

.close-thread-icon {
    cursor: pointer;
    margin-right: 10px;
}

.str-chat__list--thread .str-chat__message-simple__actions__action--options .str-chat__message-actions-box {
    left: initial;
    right: 100%;
    border-radius: var(--border-radius-md);
}

.chat-empty-channel-message {
    display: flex;
    height: calc(100vh - 80px);
}

.chat-empty-channel-message div {
    margin: auto;
    width: 60%;
    font-weight: 600;
    text-align: center;
}

.chat-empty-channel-message div button {
    margin-top: 24px;
    background-color: white;
    border-radius: 8px;
    padding: 5px 10px;
    border: 1px solid var(--main-dark-blue);
    color: var(--main-dark-blue);
}
