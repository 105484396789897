#login-area {
    background: var(--main-charcoal);
    height: 100vh;
    width: 100vw;
    display: flex;
}

#login-form {
    width: 500px;
    background-color: var(--main-brown);
    border-radius: 10px;
    border: none;
    margin: auto;
    text-align: center;
    padding: 10px;
    max-height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#login-form::-webkit-scrollbar {
    display: none;
}

#login-form .logo-container {
    display: flex;
}

#login-form img {
    height: 95px;
    margin: auto;
    padding-top: 25px;
}

#login-form h4 {
    font-size: 18px;
    margin-top: 30px;
    color: var(--main-cream)
}

#login-form p {
    color: var(--main-cream)
}

#login-form .error-message {
    color: red;
}

#login-form .login-input {
    border-radius: 4px;
    border: 1px lightgray solid;
    width: 83%;
    height: 35px;
    font-size: 15px;
    margin-top: 20px;
    padding-left: 7px;
    color: var(--main-dark-blue)
}

#login-form button {
    margin-top: 40px;
    background-color: #025036;
    color: #E6E6E6;
    box-shadow: 2px 2px 5px var(--main-charcoal);
    font-family: 'GilmerHeavy', 'Baloo2', serif
}

#login-form button:hover {
    box-shadow: 4px 4px 7px var(--main-charcoal);
    /* animation: animate 6s infinite; */
}

@keyframes animate {
    0%   {background-color: var(--main-yellow)}
    25%  {background-color: #E97451}
    50%  {background-color: var(--main-light-green);}
    75%  {background-color: #025036}
    100% {background-color: var(--main-yellow)}
}

#login-form .links p{
    display: inline-block;
    margin: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 130px;
}

