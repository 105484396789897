:root {
  /* --primary-color: #005fff; */
  --primary-color: var(--main-dark-blue);
  --primary-color-alpha: #005fff1a;
  --light-green: #95DE82;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.str-chat *, .str-chat *::after, .str-chat *::before {
  font-family: "Baloo 2";
}

.str-chat__list  p, .str-chat__textarea textarea{
  font-family: Helvetica Neue, sans-serif !important;
  color: #464646 !important;
}

#root {
  height: 100%;
}

.app__wrapper {
  display: flex;
  flex: 1;
  /* height: 800px; */
  height: 100%;
  /* border-radius: 16px; */
  box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 4px 0px;
}

.str-chat-channel-list {
  height: fit-content;
  margin-bottom: 8px;
  overflow-y: scroll;
}

.str-chat-channel {
  height: 100%;
}

.str-chat__load-more-button {
  display: none;
}

.str-chat__input-footer {
  display: none;
}

.str-chat__date-separator {
  margin: 16px 24px;
}

.str-chat__message-notification {
  background: var(--primary-color);
  cursor: pointer;
}

.browser-notification-alert {
  text-align: center;
  background-color: #006CFF;
  color: #fff;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 23px;
}

.browser-notification-alert p {
  margin: 0;
  color: #e0e0e0;
}

.browser-notification-alert button {
  background-color: transparent;
  border: none;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

@media screen and (max-width: 960px) {
  .str-chat-channel-list.team {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}
