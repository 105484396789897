.org-connect-spotlight-open {
    display: inline-block;
    width: calc(100vw - 300px)
}

.oc-loading-container {
    display: flex;
    height: calc(100vh - 145px);
}

.oc-loading-container .loading-container-block {
    margin: auto;
}

#oc-tabs {
    height: 50px;
    text-align: center;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

#oc-tabs > p {
    display: inline-block;
    margin: 13px 15px;
    cursor: pointer;
    font-family: 'GilmerRegular', 'Baloo2', serif;
}

#oc-tabs .selected-tab {
    border-bottom: 2px solid var(--main-yellow);
    font-weight: 600;
    font-family: 'GilmerBold', 'Baloo2', serif;
}

#oc-tabs .pending-tab-notification {
    background-color: #ee4b2b;
    border-radius: 6px;
    color: white;
    margin-left: 4px;
    text-align: center;
    font-size: 12px;
    padding: 0px 7px;
    position: relative;
    top: -2px;
}

#job-search-bar {
    height: 70px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding: 0 5%;
    display: flex;
    background-color: var(--main-light-gray);
}

#job-search-bar > div {
    margin: auto;
}

#job-search-bar select, input {
    border: 1px solid rgb(0 0 0 / 10%);
    color: #464646;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
}

#job-search-bar select {
    height: 34px;
    position: relative;
    top: 1px;
}

#job-search-bar .pay-rate-filter {
    width: 30px;
    margin-left: -120px;
    padding-left: 142px;
    padding-right: 3px;
}

#job-search-bar .pay-rate-prefix {
    position: relative;
    color: #464646;
    font-size: 13px;
    left: 20px
}

#job-search-bar .react-datepicker-wrapper {
    width: auto;
    margin-left: 7px;
    padding: 0 5px;
}

#job-search-bar .date-range-picker::placeholder {
    font-style: unset;
    color: #464646 !important;
}

#job-search-bar .react-datepicker__tab-loop {
    display: inline-block;
}

#job-search-bar .location-radius {
    margin-left: 7px;
}

#job-search-bar .buttonMain {
    padding: 4px 5px;
    font-size: 15px;
    margin-left: 13px;
    box-shadow: none;
    color: white;
    width: 110px;
}

#job-search-bar .buttonMain:hover {
    box-shadow: 3px 9px 8px lightgray;
}

.persona-info-container {
    height: calc(100vh - 105px);
    position: relative;
    z-index: 90;
}

#create-job-page {
    height: calc(100vh - 132px);
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding: 0 5%;
    display: flex;
    background-color: var(--main-light-gray);
}

.job-container {
    background-color: white;
    margin: auto;
    width: 600px;
    border-radius: 10px;
    border: 1px solid rgb(0 0 0 / 10%);
    margin: auto;
    text-align: center;
    padding: 10px;
    max-height: 77vh;
    overflow-y: scroll;
}

.job-container h3 {
    color: #464646
}

.job-container hr {
    width: 75%;
    border-style: solid;
    border-color: rgb(0 0 0 / 10%);
    border-radius: 15px;
    border-width: 1px;
    margin-bottom: 40px;
}

.job-container .text-input {
    width: 95%;
    height: 25px;
    font-size: 15px;
    padding: 4px 8px;
    border-radius: 7px;
    border: 1px solid rgb(0 0 0 / 10%);
}

.job-container .long-text-input {
    height: 75px;
}

.job-container .text-input-label {
    text-align: left;
    font-weight: 600;
    padding-left: 15px;
    padding-bottom: 4px;
    margin-top: 15px;
}

.job-container #job-type-select {
    width: calc(98%);
    height: 38px;
    border-radius: 7px;
    border: 1px solid rgb(0 0 0 / 10%);
}

.job-container #pay-rate-prefix {
    position: relative;
    left: 6px;
    top: 0px;
    color: darkgray;
}

.job-container #pay-rate-suffix {
    top: -27px;
    position: relative;
    float: left;
    left: 55px;
    height: 0;
    font-size: 12px;
}

.job-container .payrate-helper-1 {
    color: #ff1a1a;
    margin-right: 5px;
    margin-bottom: -1px;
}

.job-container .payrate-helper-2 {
    color: #e6e600;
    margin-right: 5px;
    margin-bottom: -1px;
}

.job-container .payrate-helper-3 {
    color: lightgreen;
    margin-right: 5px;
    margin-bottom: -1px;
}

.job-container .pay-rate {
    padding-left: 19px;
    width: calc(95% - 11px);
    margin-left: -9px;
}

.job-container .employment-button-container {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: space-evenly;
}

.job-container .employment-button {
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    color: #464646;
    background-color: #e6e6e6;
    font-weight: 600;
    margin-top: 10px;
}

.job-container .employment-button-selected {
    border: 2px solid #c02aba
}

.job-container .react-datepicker__day--selected {
    background-color: var(--main-brown);
}

.job-container .half-screen-label {
    flex: 50%;
}

.job-container .half-screen-input {
    flex: 50%;
    margin-left: 6px;
    margin-right: 6px;
}

.job-container .buttonMain {
    margin: 40px;
    color: white;
    font-family: 'GilmerBold', 'Baloo2', serif;
}

.job-container .buttonMain:hover {
    color: var(--main-yellow);
    box-shadow: 9px 11px 9px var(--main-yellow);
}

.job-container .boosted-job-container {
    text-align: left;
    margin-left: 30px;
    margin-top: 15px;
    float: left;
    width: fit-content;
}

.job-container .boosted-job-container .MuiFormControlLabel-labelPlacementEnd {
    background-color: #ecbfea;
    padding: 0 15px 0 5px;
    border-radius: 21px;
    height: 36px;
    margin-right: 8px;
}

.job-container .boosted-job-container .MuiFormControlLabel-label {
    font-size:  14px;
    color: #c02aba;
    font-family: inherit;
    font-weight: 600;
    margin-left: -4px;
}

.job-container .boosted-job-post-text {
    font-size: 14px;
    line-height: 17px;
    float: right;
    width: 72%;
    padding-right: 16px;
    margin-top: 10px;
    text-align: left;
    position: relative;
    top: 5px;
}


#jobs-list {
    height: calc(100vh - 132px);
    padding: 0 5%;
    display: flex;
    background-color: var(--main-light-gray);
    flex-direction: column;
    overflow-y: scroll;
}

.job-item-container {
    margin: 15px auto 0;
    background-color: white;
    height: fit-content;
    width: 100%;
    max-width: 900px;
    border-radius: 8px;
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 20px 30px;
    padding-bottom: 10px;
    cursor: pointer;
}

.job-item-container:hover {
    box-shadow: 7px 7px 8px rgb(0 0 0 / 10%);
}

.job-item-container h4 {
    display: inline-block;
    margin-left: 2px;
}

.job-item-container .created-since {
    float: right;
    font-size: 12px;
    color: #8c8c8c;
}

.pill-info {
    font-size: 12px;
    font-family: 'GilmerMedium', 'Baloo2', serif;
    padding: 4px 7px;
    border-radius: 7px;
}

.job-item-container .freelancer-RDH {
    color: #FF5733;
    background-color: #ffcfc5;
}

.job-item-container .freelancer-FD {
    background-color: #ffecb7;
    color: #cc9800;
}

.job-item-container .freelancer-DA {
    color: #637a89;
    background-color: #bae1ff;
}

.job-item-container p {
    color: #6a6a6a;
    line-height: 1.2em;
    margin: 10px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.job-datetime-pill {
    margin-right: 10px;
    background-color: #ececec;
    color: #707070;
}

.job-pay-pill {
    background-color: #c1eadb;
    color: #008655;
    margin-right: 10px;
}

.job-item-container .location-arrow {
    font-size: 12px;
    color: #707070;
    margin-top: 3px;
    position: relative;
    top: 1px;
}

.job-item-container .job-location {
    font-size: 14px;
    color: #707070;
    margin-left: 5px;
    margin-top:-3px;
}

.job-item-container .location-container {
    margin-top: 4px;
}
.job-item-container .edit-icons-container {
    float: right;
    position: relative;
    top: -8px;
}

.job-item-container .edit-icon {
    font-size: 13px;
    margin-right: 8px;
    color: darkgray
}

.job-item-container .delete-icon {
    font-size: 13px;
    color: #e06666;
    opacity: 0.85;
}

.job-item-container .applicant-help-text {
    font-size: 13px;
    color: #707070;
    letter-spacing: .0rem;
    margin-left: 7px;
    position: relative;
    top: -1px;
}


.popup-screen {
    background-color: rgba(128,128,128,0.8);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
}

.popup-screen-spotlight-open {
    width: calc(100vw - 300px);
}

.popup-screen .close-popup {
    color: #464646;
    background-color: white;
    padding: 5px 13px;
    border-radius: 100%;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    font-weight: 800;
}

.job-view-container {
    padding-left: 0;
    padding-right: 0;
}

.job-view-container hr {
    margin-bottom: 20px;
}

.job-view-container .long-text {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
}

.job-view-container .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 20px;
}

.job-view-container .job-detail-label {
    color: #707070;
    text-align: left;
    margin-bottom: 5px;
    line-height: 1.75;
}

.job-view-container .job-detail-data {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 20px;
}

.job-view-container .column {
    display: flex;
}

.job-view-container .job-datetime-pill {
    margin-right: 0;
}

.job-view-container .job-location-pill {
    text-align: left;
    background-color: #ecbfea;
    color: #c02aba;
    cursor: pointer;
}

.job-view-inner-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
}

.job-view-inner-container .office-heading-style {
    text-decoration: underline;
    cursor: pointer;
}

.job-view-container .applicant-heading {
    border-top: 2px solid rgb(0 0 0 / 10%);
    border-bottom: 2px solid rgb(0 0 0 / 10%);
}

.job-view-container .payment-info {
    padding: 0;
}

.job-view-container .payment-info .column {
    width: 197px;
}

.job-view-container .payment-info .total-amt {
    font-family: 'GilmerBold', 'Baloo2', serif
}

.job-view-container .payment-info .additional-hours > input {
    height: 13px;
    width: 40px;
}

.job-view-container .info-icon {
    color: #707070;
    height: 13px;
    position: relative;
    top: -4px;
    left: 4px;
    cursor: pointer;
}

.job-view-container .info-popup-container {
    height: 0;
    width: 0;
    display: none;
}

.job-view-container .info-popup {
    background:#F8F8F8;
    border: 4px solid #DFDFDF;
    color: #717171;
    font-size: 13px;
    height: fit-content;
    line-height: 15px;
    position: relative;
    text-align: left;
    top: -115px;
    left: 30px;
    display: block;
    padding:5px 0 5px 8px;
    width: 250px;
}

.info-icon:hover ~ .info-popup-container {
    display: block;
}

.job-view-container .applied-icon {
    margin-left: 7px;
}

.job-view-container .freelancer-button-container {
    border-top: 2px solid rgb(0 0 0 / 10%);
}

.job-view-container .freelancer-button-actions {
    font-size: 14px;
    width: 180px;
    margin: 40px 15px;
    padding-left: 0;
    padding-right: 0;
}

.job-view-container .remove-application {
    background-color: #e06666;
    color: white;
}

.job-view-container .message-office-button {
    background-color: #707070;
    color: white;
}

.job-view-container .profile-img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 100%;
    position: relative;
    top: 10px
}

.job-view-container .applicant-row-container {
    border-bottom: 2px solid rgb(0 0 0 / 10%);
    height: 80px;
    cursor: pointer;
}

.job-view-container .profile-img-container {
    float: left;
    left: 13px;
    position: relative;
    margin-right: 27px;
}

.job-view-container .applicant-info {
    padding-top: 8px;   
    text-align: left;
    position: relative;
    float: left;
    font-size: 14px;
    width: calc(100% - 270px);
}

.job-view-container .applicant-info > p:first-of-type {
    font-weight: 600;
}

.job-view-container .applicant-info > p > span {
    color: #b9b9b9;
    font-size: 12px;
    position: relative;
    top: -1px;
}

.job-view-container .applicant-info .applicant-bio {
    width: 98%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.job-view-container .empty-applicants {
    margin: 25px;
    margin-top: 30px;
}

.job-view-container .profile-img-placeholder {
    width: 60px;
    height: 60px;
    background-color: var(--main-dark-blue);
    border-radius: 100%;
    position: relative;
    top: 10px;
    color: white;
    font-size: 28px;
    line-height: 2.2;
}

.job-view-container .accept-button {
    position: relative;
    top: 28px;
    background-color: #008655;
    border: 1px solid #008655;
    color: white;
    padding: 3px 15px;
    border-radius: 7px;
    font-size: 14px;
    float: right;
    margin-right: 20px;
}

.job-view-container .time-passed-button {
    margin-right: 15px;
    background-color: #ececec;
    border: 1px solid #ececec;
    color: #707070;
    cursor: not-allowed;
}

.job-view-container .time-passed-button:hover {
    box-shadow: none !important;
}

.job-view-container .message-button {
    color: white;
    border: 1px solid #ececec;
    background-color: #ececec;
    border-radius: 7px;
    position: relative;
    top: 28px;
    padding: 3px 15px;
    font-size: 14px;
    margin-right: 13px;
    box-shadow: 1px 1px 3px darkgray;
}

.job-view-container .accept-button:hover, .message-button:hover{
    box-shadow: 7px 7px 8px lightgray;
}

.job-view-container .message-button-icon {
    margin-bottom: -1px; 
    color: #707070;
}

.job-view-container .awaiting-job-completion {
    font-size: 15px;
    position: relative;
    top: 30px;
    color: #707070;
    background-color: #ececec;
    float: right;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 6px;
}

.job-view-container .job-complete {
    background-color: white;
}

.job-view-container .applicant-button-container {
    display: flex;
    justify-content: flex-end;
}

.job-view-container .pre-apply-tasks-container {
    width: 80%;
    text-align: center;
    margin: auto;
    margin-top: -17px;
}

.job-view-container .button-actions-apply {
    margin: 25px
}

.job-view-container .pre-apply-tasks-container > p {
    text-align: left;
}

.job-view-container .pre-apply-tasks-container .task-completed {
    text-decoration: line-through;
    color: lightgray
}

.job-view-container .pre-apply-tasks-container .complete-task-icon {
    position: relative;
    top: 1px;
}

#org-connect-mobile-user-profile {
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
}


@media only screen and (max-width: 800px) {
    #oc-tabs {
        overflow-x: scroll;
        white-space: nowrap;
    }

    #job-search-bar {
        overflow-x: scroll;
        white-space: nowrap;
    }

    .job-item-container {
        min-width: 400px;
    }

    .job-view-container .applicant-info > p > span {
        display: none;
    }

    .job-view-container .applicant-info > p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100px;
    }

    .job-container #pay-rate-prefix {
        float: left;
        top: 30px;
        left:-1px;
    }
}

