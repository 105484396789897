.edit-channel__container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* .edit-channel__container_open {
    width: calc(100% - 300px);
} */

.edit-channel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding-right: 20px;
}

.edit-channel__header p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-left: 20px;
}

.edit-channel__header svg {
    cursor: pointer;
}

.channel-name-input__wrapper {
    display: flex;
    flex-direction: column;
    height: 169px;
    padding-left: 20px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.channel-name-input__wrapper p {
    font-size: 16px;
    line-height: 100%;
    color: #2c2c30;
    margin-top: 30px;
}

.channel-name-input__wrapper input {
    font-size: 18px;
    color: rgba(0, 0, 0);
    height: 50px;
    width: 90%;
    max-width: 540px;
    background: #f7f6f8;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 16px;
}

.channel-name-input__wrapper input:focus {
    border: 1px solid var(--primary-color);
    outline: none;
}

.channel-name-input__wrapper input::placeholder {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
}

.edit-channel__button-wrapper {
    height: 82px;
    /* background: #f7f6f8; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom-right-radius: 16px;
    border-top: 1px solid rgb(0 0 0 / 10%);
}

.edit-channel__button-wrapper p {
    background: var(--main-light-green);
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    color: #ffffff;
    margin-right: 18px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 3px;
    box-shadow: 5px 4px 10px lightgrey;
}

.leave-channel-button-container {
    display: inline-flex;
    float: right;
    height: 100%;
}

.leave-channel-button {
    margin: auto 25px auto 0;
    height: 35px;
    border: 2px solid var(--main-red-button);
    border-radius: 8px;
    background-color: white;
    color: var(--main-red-button);
    cursor: pointer;
    font-size: 14px;
}

.leave-channel-button:hover {
    background-color: var(--main-red-button);
    color: white;
}

.gray-channel-button {
    border-color: darkgray;
    color: white;
    background-color: darkgray;
}

.gray-channel-button:hover {
    background-color: darkgray;
}

.edit-channel-tab {
    display: inline-block;
    width: fit-content;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgb(0 0 0 / 13%);
    border-bottom: none;
    box-shadow: -1px -3px 11px 0px rgb(0 0 0 / 12%);
    margin-top: 30px;
    margin-right: 10px;
    height: 17px;
    cursor: pointer;
    position: relative;
}

.edit-channel-tab p {
  margin: 0;
  background-color: white;
  padding: 3px 17px 15px 17px;
  font-size: 15px;
}

.tab-deselected {
  box-shadow: -1px -3px 11px 0px rgb(0 0 0 / 6%);
}

.tab-deselected p {
  padding-bottom: 0;
}
