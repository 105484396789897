#payments-page {
    height: calc(100vh - 82px);
    display: flex;
    background-color: var(--main-light-gray);
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 20px;
}

#payments-page #oc-tabs {
    background-color: white;
    margin-bottom: 23px;
}

#payments-page .buttonMain {
    margin-top: 5px;
    width: 205px;
}

#payments-page .reports-button-container {
    position: relative;
    right: 3px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    top: 0px;
}

#payments-page .reports-button{
    width: 59px;
    font-size: 11px;
    padding: 7px;
    margin: 0;
}

#payments-page .reports-button:hover {
    box-shadow: 5px 7px 7px var(--main-yellow);
}

#payments-page h3 {
    margin-top: 0;
}

#payments-page .lds-grid {
    margin: 40px;
}

#payments-page .job-container {
    width: 80%;
    max-width: 1000px;
    /* margin-bottom: 17px; */
}

#payments-page .job-container .pagination-select {
    text-decoration: underline;
    cursor: pointer;
    display: inline;
    margin: 8px;
}

#payments-page hr {
    margin-bottom: 30px;
}

#payments-page table {
    border-collapse: collapse;
    color: #464646;
    width: 100%;
}

#payments-page table .row > th {
    line-height: 40px;
}

#payments-page table .row > td{
    padding: 13px;
    vertical-align: top;
    border-top: 1px solid rgb(0 0 0 / 10%);
}

#payments-page table .row .description {
    text-align: left;
}

#payments-page table .row .job_id {
    text-decoration: underline;
}

#payments-page table .row .job_id .job_id_shortened {
    
}

#payments-page .payment-method {
    border: 1px solid rgb(0 0 0 / 10%);
    width: 500px;
    box-shadow: 1px 3px 3px lightgray;
    height: 80px;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 30px;
}

#payments-page .payment-method .icon {
    float: left;
    padding: 22px 32px
}

#payments-page .payment-method .col {
    display: inline-block;
    width: 50%;
}

#payments-page .payment-method .col .first-info {
    margin-top: 8px;
}

#payments-page .payment-method .col > p {
    text-align: left;
    line-height: 1.4
}

#payments-page .payment-method .buttonMain {
    position: relative;
    top: -24px;
    width: 130px;
    font-size: 13px;
}

@media only screen and (max-width: 800px) {
    #payments-page .job-container {
        width: 100%;
    }

    #payments-page #oc-tabs {
        overflow-x: initial;
    }

    #payments-page .reports-button-container {
        margin-bottom: 12px;
        width: auto;
        right: unset;
        top: 5px;
    }
}

