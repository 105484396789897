#admin-header input {
    width: 400px;
    margin: 20px;
    margin-right: 0;
}

#admin-header {
    border-bottom: 1px solid darkgray;
}

#admin-header button {
    margin-left: 15px;
}

#admin-header .lds-grid-blue {
    position: relative;
    top: 19px;
    left: 15px;
}

#admin-user-results .column-left {
    height: 100vh;
    width: 50%;
    display: inline-block;
    border-right: 1px solid darkgray;
    overflow-y: scroll;
}

#admin-user-results .column-right {
    height: 100vh;
    width: calc(50% - 2px);
    float: right;
}

#admin-user-results .column-right > p, #admin-user-results .column-left > p {
    text-align: center;
    border-bottom: 1px solid darkgray;
    padding: 5px;
}

#admin-user-results .user-profile {
    border-bottom: 1px solid darkgray;
    padding-bottom: 10px;
}

#admin-user-results .user-name {
    margin: 10px;
    font-size: 16px;
}

#admin-user-results .user-label {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    display: inline-block;
}

#admin-user-results .user-item {
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
}

#admin-user-results .user-info-container {
    line-height: 1.3;
}

#admin-user-results .profile-selected-keep {
    border: 2px solid var(--main-green);
}

#admin-user-results .profile-selected-destroy {
    border: 2px solid var(--main-red-button);
}

#admin-user-results .see-jobs-button {
    float: right;
    position: relative;
    right: 10px;
}

.user-profile .delete-job {
    float: right;
    position: relative;
    top: 10px;
    right: 10px;
}