.lds-grid {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-grid div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 0.9s linear infinite;
  }
  .lds-grid-small div {
    width: 6px;
    height: 6px;
  }
  .lds-grid-white div {
    background: #fff !important;
  }
  .lds-grid-blue div {
    background: var(--main-dark-blue) !important;
  }
  .lds-grid-brown div {
    background: var(--main-brown) !important;
  }
  .lds-grid-yellow div {
    background: var(--main-yellow) !important;
  }
  .lds-grid div:nth-child(1) {
    top: 4px;
    left: 4px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 4px;
    left: 16px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 4px;
    left: 28px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 16px;
    left: 4px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 16px;
    left: 16px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 16px;
    left: 28px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 28px;
    left: 4px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 28px;
    left: 16px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 28px;
    left: 28px;
    animation-delay: -1.6s;
  }

  .lds-grid-small div:nth-child(1) {
    top: 4px;
    left: 6px;
  }
  .lds-grid-small div:nth-child(2) {
    top: 4px;
    left: 16px;
  }
  .lds-grid-small div:nth-child(3) {
    top: 4px;
    left: 26px;
  }
  .lds-grid-small div:nth-child(4) {
    top: 14px;
    left: 6px;
  }
  .lds-grid-small div:nth-child(5) {
    top: 14px;
    left: 16px;
  }
  .lds-grid-small div:nth-child(6) {
    top: 14px;
    left: 26px;
  }
  .lds-grid-small div:nth-child(7) {
    top: 24px;
    left: 6px;
  }
  .lds-grid-small div:nth-child(8) {
    top: 24px;
    left: 16px;
  }
  .lds-grid-small div:nth-child(9) {
    top: 24px;
    left: 26px;
  }


  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }



  /* button loading */

  .loading {
    font-size: 30px;
  }
  
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.25em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;    
    }
  }