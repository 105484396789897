.create-channel__container {
  /* display: flex; */
  display: inline-block;
  flex-direction: column;
  height: calc(100% - 60px);
  width: 100%
}

/* .create-channel__container_open {
  width: calc(100% - 300px);
} */

.create-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding-right: 20px;
  height: 62px;
}

.create-channel__header p {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-left: 20px;
}

.create-channel__header svg {
  cursor: pointer;
}

.channel-name-input__wrapper {
  display: flex;
  flex-direction: column;
  height: 169px;
  padding-left: 20px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.channel-name-input__wrapper p {
  
  font-size: 16px;
  line-height: 120%;
  color: #2c2c30;
  margin-top: 30px;
}

.channel-name-input__wrapper input {
  
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  height: 50px;
  width: 90%;
  max-width: 540px;
  background: #f7f6f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
}

.channel-name-input__wrapper input:focus {
  border: 1px solid var(--primary-color);
  outline: none;
}

.channel-name-input__wrapper input::placeholder {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}

.create-channel__button-wrapper {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding-top: 8px;
  
}

.create-channel__button-wrapper p {
  background: var(--main-brown);
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  color: #ffffff;
  margin-right: 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 5px 4px 10px lightgrey;
}

.error-message {
  margin-left: 20px;
    margin-bottom: -21px;
    margin-top: 10px;
    color: red;
    font-size: 14px;
}
