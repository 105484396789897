.shareable-link-box {
    border: 1px solid lightgray;
    width: fit-content;
    text-align: center;
    display: inline-block;
    padding: 4.5px 30px;
    padding-top: 5.5px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    background-color: var(--border-light-gray);
    margin-bottom: 17px;
}

.support-email-link {
    text-decoration: underline;
    color: var(--main-light-green);
}

.helper-copy-text-button {
    padding: 8px 8px 6px 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid var(--main-brown);
    background-color: var(--main-brown);
    color: white;
}

.helper-check-icon {
    padding-left: 5px;
    padding-right: 5px;
}

#sharepage .react-tel-input {
    width: calc(100% - 234px);
    display: inline-block;
}

.demo-container .react-tel-input .flag-dropdown {
    /* display: none; */
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.demo-container .react-tel-input .form-control {
    height: 37.5px;
    padding-top: 3px;
    padding-left: 8px !important;
    margin-left: 39px !important;
    width: calc(100% - 23px) !important;
    border: 1px solid #CACACA;
    border-left: none
}

#sharepage .cellphonebutton {
    margin-left: 9px;
    padding-left: 14px;
}

#sharepage .react-tel-input .form-control {
    border-right: none !important;
    border-radius: 0;
}

#sharepage .react-tel-input .flag-dropdown {
    height: 37.5px;
    margin-top: 1.5px;
}

#sharepage .form-control {
    position: relative;
    top: -2px;
}

.demo-mask {
    height: calc(100vh - 80px);
    display: flex;
    background-color: rgb(0 0 0 / 8%);
}

.demo-container {
    margin: auto;
    height: fit-content;
    background-color: #fff;
    width: 500px;
    border-radius: 16px;
    border: 1px solid lightgray;
    box-shadow: 6px 6px 13px rgb(0 0 0 / 8%);
    padding: 0 25px 25px;
}

.demo-container > div {
    color: var(--main-dark-gray);
    text-align: center;
}

.demo-container > div h2 {
    font-size: 28px;
}

.demo-container > div p {
    font-size: 16px;
}

.demo-container > div > div {
    color: var(--main-brown);
    font-size: 27px;
    margin-top: -12px;
    margin-bottom: 9px;
}