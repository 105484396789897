.popup-messenger-mask {
    position: absolute;
    display: flex;
    background-color: lightgray;
    background: rgba(0,0,0,0.3);
    height: calc(100% - 80px);
    width: calc(100% - 260px);
    z-index: 9;
}

.popup-messenger-container {
    margin: auto;
    height: 200px;
    width: 500px;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    padding: 50px 30px 10px 30px;

}

.popup-messenger-container .button-container {
    display: flex;
    justify-content: center;
}


.popup-messenger-container .button-container button {
    margin: 30px 15px;
    width: 150px;
}

