.channel-preview__wrapper {
    height: 37px;
    display: flex;
    align-items: center;
}

.channel-preview__wrapper__selected {
    height: 37px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: bold;
    margin-right: 16px;
    cursor: pointer;
    width: 98%;
}

.channel-preview__wrapper:hover {
    background: rgba(0, 0, 0, 0.2);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: bold;
    margin-right: 16px;
    cursor: pointer;
    width: 98%;
}

.channel-preview__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #464646;
    padding-left: 26px;
    height: 100%;
    max-width: 255px;
    text-overflow: ellipsis;
    overflow: auto;
    white-space: nowrap;
}

.channel-preview__item.multi {
    max-width: 260px;
}

.channel-preview__item.single .str-chat__avatar {
    margin-right: 12px;
}

.channel-preview__item.multi .str-chat__avatar {
    margin-right: 0;
}

.channel-preview__item.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
}

.channel-preview__item.multi div:nth-child(2) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0px;
}

.channel-preview__item.multi p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.channel-preview__item .notification-icon {
    background-color: #ee4b2b;
    border-radius: 6px;
    color: white;
    margin-left: 10px;
    text-align: center;
    width: 20px;
    text-align: center;
    height: 20px;
    font-size: 13px;
}
