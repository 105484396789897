.team-message-input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: #f7f6f8; */
  padding: 15px 20px 28px;
  width: 100%;
}

.team-message-input__wrapper.thread-open {
  border-bottom-right-radius: 0px;
}

.team-message-input__input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.team-message-input__input:focus-within {
  /* border-color: var(--primary-color); */
}

.team-message-input__top {
  min-height: 43px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.team-message-input__wrapper .giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: var(--primary-color);
  border-radius: 12px;
  margin-left: 8px;
}

.team-message-input__wrapper .giphy-icon__text {
  
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

.team-message-input__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  background: rgba(0, 0, 0, 0.05);
}

.team-message-input__button {
  position: relative;
  width: 0;
  right: 32px;
  cursor: pointer;
}

.team-message-input__input:focus-within .team-message-input__button path {
  fill: darkgreen;
  fill-opacity: 1;
}

.team-message-input__wrapper .str-chat__textarea textarea {
  display: flex;
  background: #fff;
  border: none;
  outline: none;
  padding-right: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.team-message-input__wrapper .str-chat__textarea textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background: #fff;
}

.team-message-input__wrapper .str-chat__textarea textarea::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.team-message-input__icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.team-message-input__icons div:first-child {
  margin: 0 5px;
}

.team-message-input__icons div:nth-child(3) {
  margin-left: 5px;
}

.team-message-input__icons svg {
  width: 36px;
}

.team-message-input__icons svg:hover path {
  fill: var(--primary-color);
  fill-opacity: 1;
  opacity: 1;
}

.team-message-input__icons svg:hover {
  cursor: pointer;
}

.icon-divider {
  width: 1px;
  height: 40px;
  background: rgba(204, 204, 204, 0.5);
}

.team-message-input__wrapper .str-chat__input--emojipicker {
  z-index: 3;
  position: absolute;
  bottom: 112px;
  left: 20px;
  width: 338px;
}

.emoji-mart .emoji-mart-emoji:focus {
  outline: none;
}

div.rfu-dropzone:focus {
  outline: none;
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  position: relative;
  width: 100%;
  background: #fff;
  margin: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rta__entity--selected {
  background: var(--primary-color);
}

.str-chat__slash-command:hover {
  background: var(--primary-color);
  cursor: pointer;
}

.rta__list-header {
  
  font-size: 14px;
  line-height: 16px;
  color: #2c2c30;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.rfu-image-upload-button {
  margin-top: 7px;
}

.rfu-image-upload-button svg {
  height: 17px;
}

.rfu-image-upload-button svg > path {
  fill: black;
  opacity: 0.2
}

.rfu-image-upload-button svg > path:hover {
  opacity: 1
}


