#settings .section-title {
    text-align: left;
    margin: 0;
    margin-left: 25px;
}

#settings table {
    margin-top: 25px;
    text-align: left;
}

#settings td, #settings th {
    font-size: 16px;
    padding: 7px;
}

#settings table .checkbox, th {
    text-align: center;
}

#settings .settings-button {
    color: white;
    margin-top: 40px;
    margin-bottom: 10px;
}
